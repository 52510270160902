import { render, staticRenderFns } from "./ImportData.vue?vue&type=template&id=e3aaeba2&scoped=true&"
import script from "./ImportData.vue?vue&type=script&lang=js&"
export * from "./ImportData.vue?vue&type=script&lang=js&"
import style0 from "./ImportData.vue?vue&type=style&index=0&id=e3aaeba2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e3aaeba2",
  null
  
)

export default component.exports