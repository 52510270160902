<template>
  <div class="answerList">
    <!-- 答题数据 -->
    <div class="btn">
      <div class="searchBtn">
        <el-select v-model="gaugeId" placeholder="请先选择量表" size="small" @change="getResultList">
          <el-option v-for="item in GaugeList" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
        <el-cascader :options="OrganizationsList" v-model="searchInfo.deptId" size="small" placeholder="请选择组织机构" @focus="judgeGauge"
          :show-all-levels="false" :props="{ value: 'id', label: 'name', checkStrictly: true, emitPath: false }">
        </el-cascader>
        <!-- <el-cascader v-model="searchInfo.deptId" size="small" placeholder="请选择组织机构" :options="OrganizationsList"
          :props="{ checkStrictly: true }" clearable>
        </el-cascader> -->
        <el-select v-model="searchInfo.planId" placeholder="请选择测评计划" size="small" @focus="judgeGauge">
          <el-option v-for="item in PlanList" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
        <el-input placeholder="请输入姓名" @focus="judgeGauge" v-model="searchInfo.nickname" clearable size="small">
        </el-input>
        <el-button type="primary" size="small" @click="search">搜索</el-button>
      </div>
      <div class="anotherBtn">
        <!-- <el-button type="primary" size="small" @click="deleteResult">回收站</el-button> -->
        <el-button type="primary" size="small" @click="exportData(2)">导出</el-button>
        <!-- <el-dropdown style="margin-left: 5px;" split-button type="primary" @command="exportData" size="small">
          导出答题数据
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item :command="1">全部数据</el-dropdown-item>
            <el-dropdown-item :command="2">勾选数据</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown> -->
        <el-button type="primary" size="small" @click="importExport">导入</el-button>
      </div>
    </div>
    <div class="tableContent">
      <el-table :data="tableData" border v-loading="loading" style="width: 100%" height="730"
        @selection-change="handleSelectionChange" size="small">
        <el-table-column type="selection">
        </el-table-column>
        <el-table-column prop="id" label="ID">
        </el-table-column>
        <el-table-column prop="nickname" label="姓名" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="sex" label="性别">
          <template slot-scope="scope">
            <span v-if="scope.row.sex === 1"> 男</span>
            <span v-if="scope.row.sex === 2"> 女</span>
            <span v-if="scope.row.sex === 3"> 未设置</span>
          </template>
        </el-table-column>
        <el-table-column prop="age" label="年龄">
        </el-table-column>
        <el-table-column prop="submitTime" label="提交时间" width="160">
          <template slot-scope="scope">
            <span v-if="scope.row.submitTime">{{ scope.row.submitTime }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="time" label="答题时长">
          <template slot-scope="scope">
            <span>{{ scope.row.time }}</span>
          </template>
        </el-table-column>
        
        <el-table-column v-for="item in scoreWarningVOMap" :key="item.id"  :label="item">
          <template slot-scope="scope">
            <span>{{ scope.row.scoreMap[item] }}</span>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
       <!-- <span>共 {{total}} 条</span> -->
       <el-pagination @current-change="handleCurrentChange" :current-page="paging.p" :page-size="paging.ps"
                layout="total, prev, pager, next, jumper" :total="paging.total">
            </el-pagination>
    </div>
    </div>
    <ImportExcelVue ref="import"></ImportExcelVue>
  </div>
</template>

<script>
import { getPlanResultList, deletePlanResult, exportPlanResult } from '@/api/evaluationManagement/result'
import { getOrganizationList } from '@/api/system/organization'
import { getGaugeList } from '@/api/gauge/gaugeList.js'
import { getPlanList } from '@/api/evaluationManagement/plan'
import { getAnswerDataList } from '@/api/evaluationManagement/answerData'
import ImportExcelVue from './ImportExcel.vue'
export default {
  data() {
    return {
      gaugeId: null,
      loading: false,
      total: 0,
      searchInfo: {
        deptId: null,
        planId: null,
        nickname: null
      },
      paging: {
        p: 1,
        ps: 200,
        total: 0
      },
      tableData: [],
      GaugeList: null,
      scoreWarningVOMap: [], // 表头数据
      OrganizationsList: [], // 组织机构列表
      PlanList: [], //测评计划列表
      deleteResultIdList: [],
      selectedList: [] //用户点击选择的数组
    }
  },
  methods: {
    // 获取量表列表
    async getGaugesList() {
      const res = await getGaugeList({ p: 1, ps: 100000, status: 2 })
      // console.log(res);
      if (res.code === 200) {
        this.GaugeList = res.data.records;
      }
    },
    // 获取答题数据列表
    async getResultList() {
      this.loading = true;
      let info = JSON.parse(JSON.stringify(this.searchInfo));
      // console.log(info);
      // 如果组织机构选择的选择的是全部需要把值改为null
      if (info.deptId === -1) {
        info.deptId = null
      }
      if (info.planId === -1) {
        info.planId = null;
      }
      console.log(info);
      const res = await getAnswerDataList(this.paging, this.gaugeId, { ...info });
      console.log(res);
      if (res.code === 200) {
        this.loading = false;
        this.tableData = res.data.userAnswerDataVOS;
        this.paging.total = res.data.total;
        // this.total = this.tableData.length;
        // 转换表头
        // 对象直接遍历出来会乱序，所以要把对象转换为数组排序
        this.scoreWarningVOMap = res.data.orderNumList;
        
        // if (res.data.userAnswerDataVOS.length > 0) {
        //   let arr = res.data.userAnswerDataVOS[0].scoreMap;
        //   let newKey = Object.keys(arr).sort();
        //   let newObj = {};
        //   for (var i = 0; i < newKey.length; i++) {
        //     newObj[newKey[i]] = arr[newKey[i]];;
        //   }
        //   console.log(newObj)
        //   for (let key in newObj) {
        //     this.scoreWarningVOMap.push(key);
        //   }
        // }
        // console.log(this.scoreWarningVOMap)
      }
    },
        // 切换分页
        handleCurrentChange(val) {
            this.paging.p = val;
            this.getResultList();
        },
    // 获取组织机构列表
    async getOrganizationsList() {
      const res = await getOrganizationList()
      // console.log('组织机构');
      // console.log(res);
      if (res.code === 200) {
        this.OrganizationsList = res.data;
        this.OrganizationsList = [{ id: -1, name: '全部' }, ...this.OrganizationsList];
      }
    },
    // 获取测评列表
    async getList() {
      const res = await getPlanList({ p: 1, ps: 100000 });
      console.log(res);
      if (res.code === 200) {
        this.PlanList = res.data.records;
        this.PlanList = [{ id: -1, name: '全部' }, ...this.PlanList];
        // this.PlanList.push()
      }
    },
    // 点击搜索
    search() {
      if (this.gaugeId === null) {
        this.$message({
          showClose: true,
          message: '请先选择量表'
        });
      } else {
        this.getResultList();
      }

    },
    // 用户点击选择
    handleSelectionChange(val) {
      // console.log(val);
      this.selectedList = val;
      this.deleteResultIdList = [];
      if (val.length > 0) {
        val.forEach(item => {
          this.deleteResultIdList.push(item.userAnswerId);

        })
      }
    },
    // 移入回收站
    async deleteResult() {
      if (this.deleteResultIdList.length > 0) {
        const res = await deletePlanResult(JSON.stringify(this.deleteResultIdList));
        console.log(res)
        if (res.code === 200) {
          this.getResultList();
        } else {
          this.$message({
            showClose: true,
            message: '操作失败' + res.msg,
            type: 'error'
          });
        }
      } else {
        this.$message({
          showClose: true,
          message: '请选择测评结果！'
        });
      }
    },
    // 判断是否选择了量表
    judgeGauge() {
      if (this.gaugeId === null) {
        this.$message({
          showClose: true,
          message: '请先选择量表！'
        });
      }
    },
     // 导出
    async exportData(val) {
      // console.log(this.selectedList);
     if(val === 1) {
      const elink = document.createElement('a');
        elink.href = `${this.$store.state.requestApi}/plan/excel/export?gaugeId=${this.gaugeId}&type=2&userAnswerIds=0&way=1`;
        // elink.href = `http://101.34.136.224:8080/warning/export?ids=${list}&type=${val}`;
        elink.click();
     } else{
      if (this.selectedList.length > 0) {
        console.log(this.selectedList);
        let userAnswerIds = [];
        this.selectedList.forEach(item => {
          userAnswerIds.push(item.userAnswerId);
        });
        // let info = {gaugeId: this.gaugeId, userAnswerIds: userAnswerIds, type: 1};
        let list = userAnswerIds.join(',');
        const elink = document.createElement('a');
        elink.href = `${this.$store.state.requestApi}/plan/excel/export?gaugeId=${this.gaugeId}&type=2&userAnswerIds=${list}&way=2`;
        // elink.href = `http://101.34.136.224:8080/warning/export?ids=${list}&type=${val}`;
        elink.click();
        console.log(userAnswerIds)
      } else {
        this.$message({
          showClose: true,
          message: '请选择测评结果！'
        });
      }
     }

    },
    // 导入
    async importExport() {
      // console.log(11)
      this.$refs.import.openImport();
      // if (this.selectedList.length > 0) {
      //   this.$refs.import.openImport();
      // } else {
      //   this.$message({
      //     showClose: true,
      //     message: '请选择量表！'
      //   });
      // }
    }
  },
  created() {
    // this.getResultList();
    this.getGaugesList();
    this.getOrganizationsList();
    this.getList();
  },
   mounted() {
    this.$message({
          showClose: true,
          message: '请先选择量表'
      });
  },
  components: { ImportExcelVue }

}
</script>

<style lang="scss" scoped>
.answerList {
  padding: 20px;
  padding-bottom: 0px;
  .block{
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .btn {
    display: flex;
    justify-content: space-between;

    .anotherBtn {
      .el-button {
        margin-left: 20px;
      }
    }
  }

  .tableContent {
    margin-top: 20px;
  }

}
</style>